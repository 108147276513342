@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
Section 1.
This section covers the custom CSS from scratch which will be used all of the components and pages. 
*/
.grad-bg {
  background: linear-gradient(to right, #0b86a5, #b6fff9);
}

.h-445px {
  height: 445px;
}
/* 
Section 2. 
This section covers all of the custom classes which inherit the class features from the standard tailwind classes. 
*/


