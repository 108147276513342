.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 10;
    top: 0%;
    left: 0%;
    position: fixed;
    height: 100%;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }